<template>
  <div class="live">
    <div class="left">
      <fullscreen :fullscreen.sync="fullscreen"
                  style="width: 100%; height: 100%">
        <WindowLives :config="windowConfig"
                     @setActiveBigLive="setActiveBigLive" />
      </fullscreen>
    </div>
    <div class="right">
      <LiveLists ref="liveLists"
                 :list="allLiveList"
                 @changePageState="changePageState"
                 @chooseOneLive="chooseOneLive" />
    </div>
    <!-- <div class="little-right flex" v-show="!isFour">
      <img
        :src="icons.four_c1c1c1"
        alt=""
        @click.stop="changePageState('four')"
      />
      <img
        :src="icons.nine_ffffff"
        alt=""
        @click.stop="changePageState('nine')"
      />
      <img
        :src="icons.big_c1c1c1"
        alt=""
        @click.stop="changePageState('big')"
      />
    </div> -->
    <Spin size="large" fix v-if="spinShow"></Spin>
    <Button @click="toggle" type="info" class="fullscreen">全屏显示</Button>
  </div>
</template>

<script>
import WindowLives from './window-lives.vue';
import LiveLists from './live-lists.vue';
import big_c1c1c1 from '@/assets/img/statistics/big_c1c1c1.png';
import four_c1c1c1 from '@/assets/img/statistics/four_c1c1c1.png';
import nine_ffffff from '@/assets/img/statistics/nine_ffffff.png';
import Api from '@/utils/api.js';
import _ from 'lodash';

import fullscreen from 'vue-fullscreen';
import Vue from 'vue';
Vue.use(fullscreen);

export default {
  name: 'live',
  components: { WindowLives, LiveLists },
  data() {
    return {
      icons: {
        big_c1c1c1,
        four_c1c1c1,
        nine_ffffff,
      },
      windowConfig: {
        pageType: 'four',
        fourLiveList: [],
        liveList: [],
        activeBigLive: {},
      },
      spinShow: true,
      hadChoosedCount: 0,
      allList: [],
      allLiveList: [],
      pageType: 'four',
      fullscreen: false,
    };
  },
  mounted() {
    this.user_info = JSON.parse(
      window.sessionStorage.getItem('user_info') || '{}'
    );
    this.initData();
  },
  computed: {
    // 4个格子
    isFour() {
      return this.pageType === 'four';
    },
    // 9宫格
    isNine() {
      return this.pageType === 'nine';
    },
    // 大屏显示
    isBig() {
      return this.pageType === 'big';
    },
    // 超管角色
    isSuperAdmin() {
      return (
        JSON.parse(window.sessionStorage.getItem('user_info') || '{}').type ===
        0
      );
    },
  },
  methods: {
    // 选择一个视频填充进4个格子 4个格子的内容按照 总选择的数量 % 4 的顺序选取 选中已选的不做处理
    chooseOneLive(item) {
      console.log(item);
      // console.log(
      //   this.windowConfig.fourLiveList.findIndex(
      //     (ele) => ele.user_id === item.user_id
      //   )
      // );
      //   if (
      //     this.windowConfig.fourLiveList.findIndex(
      //       (ele) => ele.user_id === item.user_id
      //     ) !== -1
      //   )
      //     return;
      let index = this.hadChoosedCount++ % 4;
      item.isWatching = true;
      if (this.windowConfig.fourLiveList.length < 4) {
        this.windowConfig.fourLiveList.push(item);
      } else {
        this.windowConfig.fourLiveList[index].isWatching = false;
        this.windowConfig.fourLiveList.splice(index, 1, item);
      }
      // this.windowConfig.liveList.forEach(ele => {
      //   if (this.windowConfig.fourLiveList.some(e => e.user_id === ele.user_id) === -1) {
      //     ele.isWatching = false
      //   }
      // })
    },

    // 获取可直播的设备
    initData() {
      this.windowConfig.fourLiveList = [];
      this.$sy_get('/manage/api/v1/live/capacity').then((res) => {
          if (res.code == 0) {
            this.allLiveList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notice.error({
            title: '获取直播列表接口异常,返回无数据',
            desc: err.toString(),
          });
        })
        .finally(() => {
          this.spinShow = false;
        });
    },
    // 切换 4 9 大屏
    changePageState(type) {
      this.pageType = type;
      this.windowConfig.pageType = type;
      if (type === 'nine' || type === 'big') {
        this.windowConfig.liveList = [].concat(this.allLiveList);
      }
    },
    // 大屏直播选择一个当做当前
    setActiveBigLive(item) {
      console.log('大屏直播选择一个当做当前', item);
      this.windowConfig.activeBigLive = item;
    },
    ///
    toggle() {
      this.fullscreen = !this.fullscreen;
    },
  },
};
</script>

<style lang="scss" scoped>
.live {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  position: relative;
  .nine-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index:2;
  }
  .left {
    flex: 1;
    background-color: #000000;
    padding: 10px;
  }
  .right {
    // flex: 0 0 340px;
    width: 340px;
    background-color: #fcfcfc;
    font-size: 16px;
    color: #333;
  }
  .little-right {
    padding-top: pcRem(36);
    flex: 0 0 pcRem(36);
    background-color: #000;
    flex-direction: column;
    justify-content: flex-start;
    img {
      margin-bottom: pcRem(20);
      cursor: pointer;
      width: pcRem(14);
      height: pcRem(14);
    }
  }
  .fullscreen {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>
