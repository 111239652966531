<template>
  <div class="window-live-item">
    <div class="top bar">
      <div class="circle"
           :style="getCircleStyles(config)"></div>
      <span>{{ config.name }}</span>
    </div>
    <div class="live">
      <!-- <video v-if="config && config.isLive" src></video> -->
      <video-item :config="config"
                  v-if="config.isWatching"
                  ref="liveVideo" />
      <!-- <img v-else :src="icons.not_live" alt /> -->
    </div>
    <div class="bottom bar"
         v-show="showBottom">
      <template v-if="config && config.is_online">
        <!-- <span class="right-item">
          <img :src="icons.drone" alt />
          <span>{{ config.DRONEMODEL }}</span>
        </span> -->
        <!-- <span class="right-item">
          <img :src="icons.time" alt />
          <span>{{ config.create_time }}</span>
        </span> -->
        <span class="right-item">
          <img :src="icons.person"
               alt />
          <span>{{ config.name }}</span>
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import drone from '@/assets/img/statistics/drone.png';
// import not_live from "@/assets/img/statistics/not_live.png";
import LiveTimeWhite from '@/assets/img/statistics/time-white.png';
import PersonnelWhite from '@/assets/img/statistics/personnel-white.png';
import VideoItem from '../watch/video-item.vue';

export default {
  name: 'window-live-item',
  components: { VideoItem },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    showBottom: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },

  data() {
    return {
      icons: {
        drone,
        time: LiveTimeWhite,
        person: PersonnelWhite,
        // not_live: not_live,
      },
    };
  },
  methods: {
    getCircleStyles(item) {
      return {
        backgroundColor: item && item.is_publish ? '#32e225' : '#D3D3D3',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.window-live-item {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #7d7d7d;
  .live {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    video {
      width: 100%;
      height: 100%;
    }
    img {
      width: 124px;
      height: 112px;
    }
  }
  .bar {
    color: #fff;
    width: 100%;
    position: absolute;
    left: 0;
    height: 36px;
    max-height: 36px;
    // background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  .top {
    z-index: 1;
    top: 0;
    .circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .bottom {
    bottom: 0;
    // justify-content: space-between;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .right-item {
      display: flex;
      align-items: center;
      flex: 1;
      &:last-child {
        flex: 0 0 100px;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      img {
        width: 16px;
        height: 16px;
        margin-right: 20px;
      }
    }
  }
}
</style>
