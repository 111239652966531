<template>
  <div class="live-lists">
    <div class="title">实时直播列表</div>
    <div class="control">
      <!-- <div class="nine"
           @click="setPageState('four')"
           style="margin-left: 19px">
        <img v-if="pageType == 'four'"
             :src="icons.four_select"
             alt="四宫格"
             class="img" />
        <img v-else
             :src="icons.four"
             alt="四宫格"
             class="img" />
      </div>
      <div class="nine"
           @click="setPageState('nine')">
        <img v-if="pageType == 'nine'"
             :src="icons.nine_select"
             alt="九宫格"
             class="img" />

        <img v-else
             :src="icons.nine"
             alt="九宫格"
             class="img" />
      </div>
      <div class="nine"
           @click="setPageState('big')">
        <img v-if="pageType == 'big'"
             :src="icons.big_select"
             alt="大屏监控"
             class="img" />

        <img v-else
             :src="icons.big"
             alt="大屏监控"
             class="img" />
      </div> -->
      <!-- <div class="remote shadow-wrapper">
          <span>远程控制</span>
          <i-switch true-color="#32E225" size="small" v-model="switch1" @on-change="change" >
            <Switch />
          </i-switch>
        </div> -->
    </div>
    <!-- 直播人员列表 -->
    <div class="list">
      <div class="item"
           v-for="(item, index) in list"
           :key="index"
           @click.stop="addLiveList(item)">
        <div class="left">
          <span>{{ item.name }}</span>
        </div>
        <!-- <div :style="getCircleStyles(item).css"
             v-show="pageType == 'four'">
          <span>{{ getCircleStyles(item).text }}</span>
        </div> -->
        <!-- <span class="head right-item">{{
            (item.detail && item.detail[0] && item.detail[0].DRONEMODEL) || ""
          }}</span>
          <span class="right-item">
            <img :src="icons.time" alt="" />
            <span>{{
              (item.detail && item.detail[0] && item.detail[0].create_time) ||
              ""
            }}</span>
          </span> -->
        <!-- <div class="msg-right" @click.stop="openChat(item)">
          <img :src="icons.msg" alt="" />
          <div v-if="item.hadNewMsg" class="red-msg"></div>
        </div> -->
      </div>
      <div class="no-data"
           v-if="!list.length">暂无直播</div>
    </div>

    <!--聊天弹框  -->
    <!-- <ChatModal
      @cancel="cancelHandle"
      :showModal="showChat"
      :operations="chatModelOperations"
      :info="liveInfo"
      @sendSocketMsg="sendSocketMsg"
    /> -->
  </div>
</template>

<script>
import GridView from '@/assets/img/statistics/gridview.png';
import LiveTime from '@/assets/img/statistics/live-time.png';
// import Personnel from "@/assets/img/statistics/personnel.png";
import big from '@/assets/img/statistics/big_screen.png';
import four from '@/assets/img/statistics/four_palaces.png';
import nine from '@/assets/img/statistics/nine_palaces.png';
import big_select from '@/assets/img/statistics/big_press.png';
import four_select from '@/assets/img/statistics/four_press.png';
import nine_select from '@/assets/img/statistics/nine_press.png';
import Msg from '@/assets/img/statistics/message.png';

export default {
  name: 'live-lists',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chatModelOperations: ['chat'],
      icons: {
        nine: GridView,
        time: LiveTime,
        // person: Personnel,
        msg: Msg,
        big,
        big_select,
        four,
        four_select,
        nine,
        nine_select,
      },
      // showChat: false,
      // liveInfo: {},
      pageType: 'four', // four nine big
    };
  },
  methods: {
    // 抛出发送消息的内容
    // sendSocketMsg(data) {
    //   this.$emit("sendSocketMsg", data);
    // },
    // 点击右上角隐藏的时候同步showChat状态
    // cancelHandle() {
    //   this.showChat = false;
    // },
    // 抛出选中一个4宫格事件的data
    addLiveList(item) {
      //   if (this.pageType == 'four') {
      this.$emit('chooseOneLive', item);
      //   }
    },
    // // 打开聊天框
    // openChat(item) {
    //   console.log(item);
    //   this.liveInfo = item;
    //   this.showChat = true;
    //   item.hadNewMsg = 0;
    // },
    // 抛出改变 4 9 大屏的类型
    setPageState(type) {
      this.pageType = type;
      this.$emit('changePageState', type);
    },
    getCircleStyles(item) {
      return {
        text: item.isWatching ? '正在观看' : '空闲',
        css: {
          color: item.isWatching ? '#61D76B' : '#ECD500',
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.live-lists {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #1F1F1F;
  .title {
    width: 100%;
    height: 50px;
    border: 1px solid #313131;
    background: #1F1F1F ;
    font-size: 18px;
    line-height: 50px;
    color: #ffffff;
    padding-left: 20px;
  }
  .control {
    flex-shrink: 0;
    padding-top: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    // .shadow-wrapper {
    //   padding: pcRem(10);
    //   background: #fff;
    //   box-shadow: 0px 3ox 6px rgba(0, 0, 0, 0.16);
    //   border-radius: 4px;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   cursor: pointer;
    // }
    .nine {
      .img {
        height: 31px;
        margin-right: 25px;
      }
    }
    .remote {
      span {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
  .list {
    flex: 1;
    overflow-y: auto;
    .item {
      width: 321px;
      height: 62px;
      border: 1px solid #313131;
      background-color: #313131 ;

      padding: 11px 16px 11px 14px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      color: #ffffff;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }

      .left {
        font-size: 16px;
        display: flex;
        align-items: center;
        img {
          width: 36px;
          height: 36px;
          border: 2px solid #ff685b;
          border-radius: 50%;
          margin-right: 22px;
        }
      }

      // .msg-right {
      //   margin-left: 10px;
      //   position: relative;
      //   img {
      //     width: 34px;
      //     height: 34px;
      //   }
      //   .red-msg {
      //     position: absolute;
      //     top: 0;
      //     right: 6px;
      //     width:10px;
      //     height: 10px;
      //     background: rgba(255, 70, 70, 1);
      //     border-radius: 50%;
      //   }
      // }
    }
  }
}
</style>
