<template>
  <div class="live-video"
       id="live-video"
       ref="live_video"
       @dblclick="fullScreen = !fullScreen">
    <!-- <video :id="videoId" class="video-js vjs-default-skin vjs-big-play-centered" width="400" height="300" preload="auto"></video> -->
    <!--<video class="video" :id="videoId"  style="background-color: #333333;width: 100%;height: 100%;"></video>-->
    <div :id="videoId"
         autoplay
         style="background-color: #333333; height: 100%"
         @click.capture.stop=""></div>
    <Spin size="large"
          fix
          v-if="spinShow"></Spin>

    <img class="FullscreenIcon"
         :src="fullScreen ? videoScreenRestore : videoFullscreen"
         alt=""
         @click="fullScreen = !fullScreen" />
  </div>
</template>

<script>
let _videoId = 0;
import Api from '@/utils/api.js';
import videoFullscreen from '@/assets/img/statistics/videoFullscreen.png';
import videoScreenRestore from '@/assets/img/statistics/videoScreenRestore.png';

export default {
  //无人机直播
  name: 'video-item',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    pageChange: {
      type: String,
      default: () => {},
    },
  },
  mounted() {
    this.init();
    // console.log("init", this.config);
    var _this = this;
    document.addEventListener('fullscreenchange', function () {
      if (document.fullscreenElement != null) {
        console.info('Went full screen');
        _this.fullScreen = true;
      } else {
        console.info('Exited full screen');
        _this.fullScreen = false;
      }
    });
  },
  watch: {
    config() {
      this.init();
      console.log('video-init');
    },
    pageChange(val) {
      console.log('video-item', val);
    },

    fullScreen(status) {
      // console.log(status);
      // console.log(document.fullscreenElement);
      if (status) {
        var ele = this.$refs.live_video;
        // var ele=document.getElementById('live-video');
        if (ele.requestFullscreen) {
          ele.requestFullscreen();
        } else if (ele.mozRequestFullScreen) {
          ele.mozRequestFullScreen();
        } else if (ele.webkitRequestFullScreen) {
          ele.webkitRequestFullScreen();
        }
      } else {
        var de = document;
        if (de.exitFullscreen) {
          de.exitFullscreen();
        } else if (de.mozCancelFullScreen) {
          de.mozCancelFullScreen();
        } else if (de.webkitCancelFullScreen) {
          de.webkitCancelFullScreen();
        }
      }
    },
    FullscreenStatus(val) {
      console.log('FullscreenStatus', val);
    },
  },
  computed: {
    videoId() {
      return 'live_video_' + _videoId++;
    },
    FullscreenStatus() {
      return (
        document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        false
      );
    },
  },
  data() {
    return {
      spinShow: true,
      playUrl: '',
      canPlay: false,
      fullScreen: false,
      videoFullscreen,
      videoScreenRestore,
      player: null,
      pilotLive:'',  //获取推流
      PilotPlay:'',//拉流的
      dataUrl:'',
    };
  },
  methods: {
    // 初始化video
    init() {
        //推流
     //this.getPushUrl()
         this.getPlayUrl()
    },
    // 获取推流
    getPushUrl(){
   //   this.$sy_post('/live/getPushUrl').then(res=>{
       this.$post('pilot/getPilotLive').then(res=>{
        console.log('获取推111111111111111111流',res)
       let url= res.data.live_url
         if(url){
          // this.getPlayUrlNEW(url);
           this.getStart(url)
         }

      })
    },
    //拉流的
    getPlayUrl(url){
      let _this=this
      let date={
        user_id:sessionStorage.getItem('sy_user_id')
      }
    //  this.$sy_post('/live/getPlayUrl').then(res=>{
    this.$post('pilot/getPilotPlay').then(res=>{
        if(res.code=== 0){
          this.getPushUrl()
        }else {
          if(url===null||url===undefined||url==='undefined'){
         /*   this.getStart(res.data.UDP_url)*/
            localStorage.setItem('flv_url', res.data.flv_url)
            localStorage.setItem('webrtc', res.data.UDP_url)
            localStorage.setItem('m3u8', res.data.m3u8_url)
            localStorage.setItem('rtmp', res.data.default_url)

            _this.player = new TcPlayer(_this.videoId, {
              //   m3u8: res.data.m3u8_url,
           //   flv: res.data.flv_url,  //拉流地址 原画 FLV 播放 URL。
            /*  m3u8: res.data.m3u8_url,*/
              webrtc:res.data.UDP_url,
            //  rtmp: res.data.live_url,
              h5_flv: true,  //是否启用 flv.js 的播放 flv。启用时播放器将在支持 MSE 的浏览器下，采用 flv.js 播放 flv，然而并不是所有支持 MSE 的浏览器都可以使用 flv.js，所以播放器不会默认开启这个属性，[v2.2.0+]
              live: true,  // 直播
              controls: 'none',//是否显示播放器的控制栏Boolean
              autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
              width: '100%', //视频的显示宽度
              height: '100%', //视频的显示高度
            });
          }else {
            _this.player = new TcPlayer(_this.videoId, {
              webrtc:res.data.UDP_url,  //直播路径
             // flv: res.data.flv_url,  //拉流地址 原画 FLV 播放 URL。
            //  rtmp: res.data.live_url,

              h5_flv: true,  //是否启用 flv.js 的播放 flv。启用时播放器将在支持 MSE 的浏览器下，采用 flv.js 播放 flv，然而并不是所有支持 MSE 的浏览器都可以使用 flv.js，所以播放器不会默认开启这个属性，[v2.2.0+]
              live: true,  // 直播
              autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
              width: '100%', //视频的显示宽度
              height: '100%', //视频的显示高度
            });
          }



        }

      })
    },
    getStart(url){
      let data={
        // 直播
        url:url,
        url_type: 1,
        video_id:
                this.config.cameras_list[1].device_sn +
                '/' +
                this.config.cameras_list[1].index +
                '/' +
                this.config.cameras_list[1].videos_list[0].index,
        video_quality: 0,
      }

      this.$sy_post('/manage/api/v1/live/streams/start',data).then((res) => {
        if (res.data) {
          if (this.player !== null) {
            console.log('this.player.destroy()');
            this.player.destroy();
            this.player = null;
          }
          this.dataUrl=res.data.url
          this.getPlayUrl(res.data.url)

        }
      }).catch((err) => {
                console.log(err);
                this.$notice.error({
                  title: '获取直播地址失败,返回无数据',
                  desc: err.toString(),
                });
              })
              .finally(() => {
                this.spinShow = false;
              });
    }
  },
  beforeDestroy() {
    if (this.player !== null) {
      this.player.destroy();  //销毁
      this.player = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.ivu-spin{
  display: none;
}
.live-video {
  width: 100%;
  height: 100%;
  position: relative;
  .FullscreenIcon {
    position: absolute;
    bottom: 30px;
    right: 10px;
    width: 20px;
    height: auto;
    opacity: 0;
    transition: opacity 1s;
    -webkit-transition: opacity 1s;
  }
}
.live-video:hover {
  .FullscreenIcon {
    opacity: 1;
  }
}
</style>
