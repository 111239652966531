<template>
  <div
    class="window-live-item"
    ref="big_video"
    @dblclick="fullScreen = !fullScreen"
  >
    <div class="top bar">
      <div class="circle" :style="getCircleStyles(config)"></div>
      <span>{{ config && config.is_publish ? "直播中" : "空闲" }}</span>
    </div>
    <div class="live">
      <!-- <video v-if="config && config.isLive" src></video> -->
      <div
        v-if="config.is_online && config.is_publish"
        style="width: 100%; height: 100%"
        ref="liveVideo"
        id="bigLive"
        @click.capture.stop=""
      />
      <!-- <img v-else :src="icons.not_live" alt /> -->
    </div>
    <div class="bottom bar" v-show="showBottom">
      <template v-if="config && config.is_online">
        <!-- <span class="right-item">
          <img :src="icons.drone" alt />
          <span>{{ config.DRONEMODEL }}</span>
        </span> -->
        <!-- <span class="right-item">
          <img :src="icons.time" alt />
          <span>{{ config.create_time }}</span>
        </span> -->
        <span class="right-item">
          <img :src="icons.person" alt />
          <span>{{ config.name }}</span>
        </span>
      </template>
    </div>
    <img
      class="FullscreenIcon"
      :src="fullScreen ? videoScreenRestore : videoFullscreen"
      alt=""
      @click="fullScreen = !fullScreen"
    />
  </div>
</template>

<script>
import drone from "@/assets/img/statistics/drone.png";
// import not_live from "@/assets/img/statistics/not_live.png";
import LiveTimeWhite from "@/assets/img/statistics/time-white.png";
import PersonnelWhite from "@/assets/img/statistics/personnel-white.png";
import Api from "@/utils/api.js";
import videoFullscreen from "@/assets/img/statistics/videoFullscreen.png";
import videoScreenRestore from "@/assets/img/statistics/videoScreenRestore.png";

export default {
  name: "window-live-item",
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    showBottom: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  watch: {
    config: {
      immediate: true,
      deep: true,
      handler: function (val) {
        if (val.is_publish === 1) {
          this.init();
          // console.log(this.playerId);
        }
        // console.log("BIG live item:", val);
      },
    },
    fullScreen(status) {
      // console.log(status);
      // console.log(document.exitFullscreen);
      // console.log(document.fullscreenElement);
      if (status) {
        let ele = this.$refs.big_video;
        // var ele=document.getElementById('live-video');
        if (ele.requestFullscreen) {
          ele.requestFullscreen();
        } else if (ele.mozRequestFullScreen) {
          ele.mozRequestFullScreen();
        } else if (ele.webkitRequestFullScreen) {
          ele.webkitRequestFullScreen();
        }
      } else {
        let de = document;
        if (de.exitFullscreen) {
          de.exitFullscreen();
        } else if (de.mozCancelFullScreen) {
          de.mozCancelFullScreen();
        } else if (de.webkitCancelFullScreen) {
          de.webkitCancelFullScreen();
        }
      }
    },
  },
  mounted() {
    var _this = this;
    document.addEventListener("fullscreenchange", function () {
      if (document.fullscreenElement != null) {
        console.info("Went full screen");
        _this.fullScreen = true;
      } else {
        console.info("Exited full screen");
        _this.fullScreen = false;
      }
    });
  },
  data() {
    return {
      player: null,
      icons: {
        drone,
        time: LiveTimeWhite,
        person: PersonnelWhite,
        // not_live: not_live,
      },
      playerId: "bigLive",
      fullScreen: false,
      videoFullscreen,
      videoScreenRestore,
    };
  },
  methods: {
    getCircleStyles(item) {
      return {
        backgroundColor: item && item.is_publish ? "#32e225" : "#D3D3D3",
      };
    },
    // 初始化video
    init() {
      // var _this = this;
      // console.log(this.config);
      if (!(this.config.IsPublish || this.config.is_publish)) {
        return;
      }
      this.$post(Api.getLiveUrl(), {
        client: "web",
        user_id: this.config.user_id,
      })
        .then((res) => {
          console.log(res);
          if (res.data) {
            if (this.player !== null) {
              this.player.destroy();
              this.player = null;
            }
            this.player = new TcPlayer(this.playerId, {
              // "m3u8": res.data.m3u8_url,
              flv: res.data.flv_url,
              // "rtmp": res.data.default_url,
              h5_flv: true,
              live: true,
              controls: "none",
              autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
              width: "100%", //视频的显示宽度
              height: "100%", //视频的显示高度
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notice.error({
            title: "获取直播地址失败,返回无数据",
            desc: err.toString(),
          });
        })
        .finally(() => {
          this.spinShow = false;
        });
    },
  },
  beforeDestroy() {
    if (this.player !== null) {
      this.player.destroy();
      this.player = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.window-live-item {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #7d7d7d;
  .live {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: #232426;
    align-items: center;
    video {
      width: 100%;
      height: 100%;
    }
    img {
      width: 124px;
      height: 112px;
    }
  }
  .bar {
    color: #fff;
    width: 100%;
    position: absolute;
    left: 0;
    height: 36px;
    max-height: 36px;
    // background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  .top {
    z-index: 1;
    top: 0;
    .circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .bottom {
    bottom: 0;
    // justify-content: space-between;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .right-item {
      display: flex;
      align-items: center;
      flex: 1;
      &:last-child {
        flex: 0 0 100px;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      img {
        width: 16px;
        height: 16px;
        margin-right: 20px;
      }
    }
  }
  .FullscreenIcon {
    position: absolute;
    bottom: 30px;
    right: 10px;
    width: 20px;
    height: auto;
    opacity: 0;
    transition: opacity 1s;
    -webkit-transition: opacity 1s;
  }
}
.window-live-item:hover {
  .FullscreenIcon {
    opacity: 1;
  }
}
</style>
